.MuiMenu-list {
  background-color: #000 !important;
}
.MuiTablePagination-input {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
html {
  scroll-behavior: smooth;
}

.MuiTablePagination-toolbar {
  min-height: 45px !important;
}
.MuiTable-root {
  border-collapse: separate !important;
}
.MuiTableRow-root {
  border-bottom-width: 1px !important;
  border-image-slice: 1;
  box-shadow: 0px 5px 5px -5px #f3ba2f;

  border-image-source: linear-gradient(
    90deg,
    #2be233 0.86%,
    #f3ba2f 49.92%,
    #ff0095 100%
  );
}
.MuiCardHeader-subheader {
  font-size: 12px !important;
  color: #ffffff !important;
}
.MuiTypography-body1 {
  font-size: 1.5rem !important;
}
.MuiStepLabel-label {
  color: rgb(158, 158, 158) !important;
  font-family: "Open Sans" !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  letter-spacing: 0.2px !important;
  padding-top: 10px !important;
}
.MuiStepLabel-root {
  display: flex !important;
  flex-direction: column !important;
}
.MuiStepIcon-root {
  font-size: 2.5rem !important;
  color: transparent !important;
  border: 1px solid rgb(156, 156, 156) !important;
  border-radius: 23px !important;
}
.Mui-completed.MuiSvgIcon-fontSizeMedium {
  color: #2be233 !important;
  border: none !important;
}
.MuiStepIcon-root.Mui-active {
  color: #ff0095 !important;
  border: none !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  fill: #000 !important;
  color: #000000;
}
.MuiStepIcon-text {
  fill: #000 !important;
  color: #000000;
}

.MuiStepConnector-root.MuiStepConnector-horizontal {
  margin-top: 20px;
}
.MuiStepper-root.MuiStepper-horizontal {
  align-items: start !important;
}
